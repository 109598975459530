* {
	box-sizing: border-box;
}

html {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div,
p,
span,
h1,
h2,
h3,
h4,
h5 {
	font-family: 'Rubik', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 400 !important;
}

h2 {
	margin: 15px 0px !important;
}

.white-bg {
	background-color: white;
	color: black;
}

#root,
#root > div,
#root > div > div {
	height: 100%;
}

#login-wrap {
	display: flex;
	height: 100%;
}

#login-container {
	flex-basis: 36%;
	height: 100%;
	padding: 30px;
	background-color: #e2e2e2;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#login-container .card {
	min-height: auto !important;
	box-sizing: content-box !important;
	padding: 100px;
	position: relative;
	margin: 0 auto;
}

#login-container .card label {
	cursor: pointer;
	position: relative;
	opacity: 0.5;
	transition: 0.5s all;
}

#login-container .card label:hover {
	opacity: 1;
}

#login-container input {
	width: 100%;
}

#login-container img {
	margin: 0 auto;
	width: 300px;
}

#login-container .ui.input {
	display: block;
	margin: 15px 0px;
}

#login-container .ui.input:last-of-type {
	margin-bottom: 0px;
}

#login-container button {
	margin: auto;
	display: block;
}

#register-container {
	flex-basis: 64%;
	height: 100%;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

#register-text {
	color: white;
	z-index: 3;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.bg {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
}

.center {
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	position: absolute;
}

#register-overlay {
	background-color: mediumpurple;
	opacity: 0.6;
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
}

#register-bg {
	float: left;
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
}

#dashboard-container {
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
}

.circle.icon {
	cursor: pointer;
}

input[type='number'] {
	text-align: center !important;
	font-size: 2em;
	font-weight: bolder;
	color: black !important;
	opacity: 1 !important;
}

button[type='submit'],
.submit {
	background-color: #43176F !important;
	color: white !important;
}

.submit.disabled,
button[type='submit'].disabled {
	background-color: #e0e1e2;
	opacity: 0.45;
}

.ui.disabled.input {
	opacity: 1 !important;
}

.selected-row,
tr:hover,
tr:hover td,
#comment-wrap .ui.segment:hover {
	background-color: #ccc !important;
}

tr:hover {
	cursor: pointer;
}

#stats-modal {
	left: 50% !important;
	top: 50% !important;
	transform: translateX(-50%) translateY(-50%) !important;
	margin: auto !important;
}

.custom-modal {
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	transform: translateX(-50%) translateY(-50%) !important;
	z-index: 9 !important;
	padding: 30px !important;
}

/* React Table */
.ReactVirtualized__Table {
	text-align: left;
}

/* Admin Dashboard */

#admin-dashboard .ui.segment {
	max-height: 800px;
	overflow: auto;
	position: relative;
}

#admin-list .item {
	font-size: 1.4em;
	padding: 15px 0px;
	cursor: pointer;
	white-space: nowrap;
}

#admin-list .item:hover {
	font-weight: bolder;
}

.ui.ribbon {
	background-color: #43176F;
	color: white;
}

.admin-menu {
	text-align: left !important;
}

@keyframes slidedown {
	from {
		top: -70px;
	}

	to {
		top: -35px;
	}
}

.animated-button {
	overflow: hidden;
	transition: all 0.3 ease;
}

.animated-button:hover .layer {
	animation-name: 'slidedown';
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
}

.layer {
	color: #fff;
	position: relative;
	top: -70px;
	width: 100%;
	left: 0;
	padding: 20px 0;
	background-color: #333;
	transition: all 0.3s ease;
	z-index: 2;
}

/* Countdown clock login */

.countBox {
	display: flex;
	flex-direction: row-reverse;
}

.count {
	padding: 5px;
}

/* Countdown clock user dashboard */
#end-countdown {
	padding: 0px 0px 10px 0px !important;
	font-size: 1em;
}

#end-countdown span.count {
	font-size: 1em !important;
}

.root-react-component-countdown-timer {
	display: block;
	margin: auto;
	padding: 30px;
	font-size: 2em;
}

.user-dashboard-countdown .root-react-component-countdown-timer {
	padding: 0px;
}

span.count {
	font-size: 2em !important;
}

#App-modal {
	z-index: 999;
	position: fixed;
	height: 100%;
	width: 100%;
	display: block;
	top: 0;
	left: 0;
}

#App-modal .fg {
	width: 400px;
	height: 120px;
	border-radius: 5px;
	padding: 15px;
	background-color: white;
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	border: 1px solid #43176F;
}

#App-modal .bg {
	background-color: #000;
	opacity: 0.75;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1;
}

/* Admin */
#admin-popup ul {
	padding-left: 10px;
}

#admin-popup ul li {
	cursor: pointer;
	padding: 10px 5px 10px 0px;
	list-style-type: none;
}

#admin-popup ul li:hover {
	font-weight: bolder;
}

/* Calendar Popup */
.popup {
	z-index: 999 !important;
}

.ui.bottom.center.popup:before {
	display: none !important;
}

#stats-modal {
	position: absolute !important;
}

#stats-modal .content,
.admin-table {
	max-height: 800px;
	position: relative;
	overflow: auto;
}

.admin-table {
	width: 100%;
}

#errorMessage {
	transition: 1s all;
	width: 450px;
	margin: auto;
	border: 1px solid red;
	position: fixed;
	z-index: 99999999999;
	top: 0;
	left: 50%;
	height: 100px !important;
	transform: translateX(-50%);
	text-align: left !important;
}

#errorMessage .icon.cancel {
	color: red;
}

@media (max-width: 1280px) {
	.FlipClock {
		transform: none !important;
		width: 80% !important;
	}
}

@media (max-width: 1279px) {
	#user-header h1 {
		font-size: 1.4em !important;
	}
}

@media (max-width: 1440px) {
	#user-dashboard.center {
		position: relative !important;
		top: auto !important;
		left: auto !important;
		transform: none !important;
	}
}

@media (max-width: 1280px) {
	#register-text h2 {
		max-height: 400px !important;
	}
}

@media (max-width: 1199px) {
	#user-dashboard input {
		width: 100px !important;
		margin: auto 30px !important;
		padding: 15px 0px !important;
	}
}

@media (max-width: 1024px) {
	#login-wrap {
		display: block;
	}

	#login-container {
		width: 100% !important;
		max-height: 700px;
	}

	#register-container {
		width: 100%;
		transform: translateY(-52%);
	}

	#user-dashboard {
		width: 95% !important;
	}
}

@media (max-width: 1023px) {
	#admin-dashboard .center {
		position: relative !important;
		top: auto !important;
		left: auto !important;
		transform: none !important;
		display: flex !important;
		flex-direction: column !important;
	}

	div.ui.menu {
		width: 100% !important;
	}

	div.ui.menu * {
		text-align: center !important;
	}

	#stats-modal {
		max-height: 90% !important;
		transform: translateX(-50%) translateY(-55%) !important;
	}
}

@media (max-width: 785px) {
	#user-dashboard {
		position: relative !important;
		display: block !important;
		top: auto !important;
		left: auto !important;
		transform: none !important;
		margin: auto !important;
		margin-top: 15px !important;
	}

	#user-dashboard #user-header {
		height: 100px !important;
	}

	#user-dashboard #user-header div {
		width: 50% !important;
	}

	#user-header-content {
		/* float: right !important; */
		/* position: relative !important; */
		transform: none !important;
		left: 50% !important;
		top: auto !important;
		display: flex !important;
	}

	#user-dashboard div {
		display: block !important;
		width: 100% !important;
	}

	#user-dashboard .card {
		height: auto !important;
	}

	#user-dashboard #user-counters {
		width: 100% !important;
		display: flex !important;
	}

	#user-dashboard #user-counters div {
		display: block !important;
		width: calc(50% - 7px) !important;
	}

	#user-dashboard #user-counters div > div {
		width: 100% !important;
	}

	#user-dashboard #user-counters div > div > div {
		display: flex !important;
	}

	#user-dashboard #user-counters .icon-wrap {
		display: flex !important;
		align-items: center !important;
	}

	#start-countdown * {
		font-size: 0.86em !important;
	}

	.user-dashboard-countdown {
		display: flex !important;
	}

	.user-dashboard-countdown h3 {
		display: none !important;
	}
}

@media (max-width: 767px) {
	#login-container,
	#register-container {
		margin: 0px !important;
	}

	#user-header div img {
		height: 70px !important;
		width: auto !important;
	}

	#user-header-content {
		top: 50% !important;
		transform: translateY(-50%) !important;
	}

	#user-header-content h1 {
		display: none !important;
	}
}

@media (max-width: 619px), (max-width: 680px) and (orientation: landscape) {
	#login-container {
		padding: 0px !important;
	}

	#login-container .card {
		width: 100% !important;
		min-height: auto !important;
		padding: 20px !important;
	}

	#login-container form {
		padding-bottom: 15px;;
	}

	#login-container .card label {
		position: relative !important;
		top: auto !important;
		transform: none !important;
		left: auto !important;
		margin: auto !important;
		bottom: auto !important;
	}
}

@media (max-width: 480px), (max-width: 680px) and (orientation: landscape) {
	#user-dashboard #user-counters {
		display: block !important;
	}

	#user-dashboard #user-counters div {
		width: 100% !important;
	}
}

@media (max-width: 680px) and (orientation: landscape) {
	#register-text {
		top: auto !important;
		left: auto !important;
		transform: none !important;
	}
}